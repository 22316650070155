import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

// images
import favicon from "/static/favicon.ico"


function getYear() {
    return new Date().getFullYear();
}

export default function Navbar(props) {
    return(
        <div>
            
            <div className="js-colorlib-nav-toggle colorlib-nav-toggle"><i></i></div>
            <div id="colorlib-aside" role="complementary" className="js-fullheight text-center">
                <div id="navbar-contents">
                    <h1 id="colorlib-logo" >
                        <AnchorLink to="/">
                            <img id="favicon" src={favicon}></img>
                            <div>Steve Leung<span>.</span></div>
                        </AnchorLink>
                    </h1>

                    <nav id="colorlib-main-menu" role="navigation">
                        <ul>
                            <li><AnchorLink to="/#colorlib-main" className="js-anchor" stripHash>Home</AnchorLink></li>
                            <li><AnchorLink to="/#research" className="js-anchor" stripHash>Research</AnchorLink></li>
                            <li><AnchorLink to="/#projects" className="js-anchor" stripHash>Projects</AnchorLink></li>
                        </ul>
                        <div id="social-media">
                            <ul>
                                <li><a href="https://github.com/stevenaleung"><i className="icon-github" aria-label="github icon"></i></a></li>
                                <li><a href="https://www.linkedin.com/in/stevenaleung"><i className="icon-linkedin" aria-label="linkedin icon"></i></a></li>
                            </ul>
                        </div>
                    </nav>

                    <div className="colorlib-footer">
                        <div className="colorlib-attribution">
                            Copyright &copy; {getYear()} All rights reserved<br></br>
                            This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank" rel="noreferrer noopener">Colorlib</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
