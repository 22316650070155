import { useEffect } from "react"

export default async function useScript (resourceUrl) {
    useEffect(() => {
        const script = document.createElement("script");
        script.async = false;
        script.src = resourceUrl;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [resourceUrl]);  // useEffect is called only when resourceUrl changes
};
